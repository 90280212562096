import React, { useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import Layout from '../components/layout';
import ErrorBoundary from '../components/error-boundary';

import Paper from '@material-ui/core/Paper';
import ButtonBase from '@material-ui/core/ButtonBase';
import LinearProgress from '@material-ui/core/LinearProgress';

import { loadStripe } from '@stripe/stripe-js';
import useAuth from '../hooks/useAuth';

import * as classes from './checkout.module.scss';

import { CREATE_CHECKOUT_URL, STRIPE_PUBLIC_KEY, ADVANCED_PLAN_PRICE_ID } from '../config';

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

const GOOGLE_SIGN_IN_IMG_SRC = '/images/btn_google_signin_light_normal_web.png';
const STRIPE_LOGO_SRC = '/images/stripe-logo.png';

export default function Checkout() {
  const [user, signIn] = useAuth();
  const [sessionId, setSessionId] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (user && !sessionId) {
      createCheckoutSession(user, ADVANCED_PLAN_PRICE_ID)
        .then(({ sessionId }) => setSessionId(sessionId))
        .catch(err => {
          console.error(err);
          setError(true);
        });
    }
  }, [user]);

  const redirectToCheckout = async () => {
    try {
      const stripe = await stripePromise;
      await stripe.redirectToCheckout({ sessionId });
    } catch (err) {
      console.error(err);
      setError(true);
    }
  };

  if (sessionId) {
    redirectToCheckout();
  }

  const handleSignInClick = () => {
    signIn();
  };

  return (
    <ErrorBoundary>
      <Layout>
        <div className='root' style={{ paddingTop: 40 }}>
          <Container maxWidth='sm'>
            <Paper>
              <div style={{ padding: 0 }}>

                {error && <div style={{ padding: 24, backgroundColor: '#fbedef' }}>
                  <h3 style={{ textAlign: 'center' }}>An error occured</h3>
                  <p>Sorry about that. Please try refreshing or <a href='mailto:support@tabmanager.io'>contact us</a> if the problem persist.</p>
                </div>}

                <SignUpForm isHidden={user || error} onSignInClick={handleSignInClick} />

                {user && !error && <div>
                  <div className={classes.header} style={{ textAlign: 'center', paddingTop: 40 }}>
                    Loading Secure Checkout...
                  </div>

                  <div className={classes.center} style={{ margin: '40px 0' }}>
                    <img
                      style={{ opacity: 0.8 }}
                      src={STRIPE_LOGO_SRC}
                      width={200}
                    />
                  </div>
                  <LinearProgress />
                </div>}
              </div>
            </Paper>

            {!user && !error &&
              <div className={classes.center} style={{ marginTop: 24, fontSize: 13 }}>
                <div>By creating an account, you agree to Tabmanager.io's</div>
                <div>
                  <a href='https://tabmanager.io/terms'>Terms of Service</a> and{' '}
                  <a href='https://tabmanager.io/privacy'>Privacy Policy</a>.
                </div>
              </div>
            }

          </Container>
        </div>
      </Layout>
    </ErrorBoundary>
  );
}

function SignUpForm({ isHidden = false, onSignInClick }) {
  return isHidden ? null : (
    <div className={classes.center} style={{ padding: 32, justifyContent: 'center' }}>
      <div className={classes.header}>
        Sign in to continue
      </div>

      <p style={{ textAlign: 'center', fontSize: 16 }}>
        {`Please sign in with the account that you will use with the tabmanager.io extension.`}
      </p>

      <div style={{ marginTop: 32 }}>
        <ButtonBase className={classes.signInButton} onClick={onSignInClick}>
          <img
            src={GOOGLE_SIGN_IN_IMG_SRC}
            width={300}
            alt='google sign-in button'
          />
        </ButtonBase>
      </div>
    </div>
  );
}

async function createCheckoutSession(user, priceId) {
  const response = await fetch(CREATE_CHECKOUT_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      priceId: priceId,
      uid: user.uid,
      email: user.email,
    })
  });

  if (!response.ok) {
    throw Error(`Network response was not ok: ${response.statusText}`);
  }

  return response.json();
}
