import React from 'react';
import PropTypes from 'prop-types';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 20 }}>
          <div
            style={{
              width: 600,
              height: 160,
              backgroundColor: '#F6F8FA',
              borderRadius: 16,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <div style={{ padding: 20, fontSize: 18 }}>
              <h3>An error occured</h3><p>Sorry about that. A developer have been notified about this issue.</p>
              <p>Please try refreshing and <a href='mailto:support@tabmanager.io'>contact us</a> if the problem persist.</p>
            </div>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.any,
};
